import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userData: {
    name: "",
    phoneNumber: "",
    cityName: "",
    userOtp: "",
    profileImage: "",
  },
  currentRoute: "",
  isLoggedIn: false,
  deviceToken: "",
  bookRider: {
    bookRiderStatus: "firstStep",
    vehicleType: "",
    dateTime: "",
    pickupLocation: "",
    dropOffLocation: "",
    currentOrderId: "",
    riderLocation: "",
  },
  bookParcel: {
    bookParcelStatus: "firstStep",
    pickupLocation: "",
    dropOffLocation: "",
    currentOrderId: "",
  },
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserData: (state, action) => {
      const userData = action.payload;
      state.userData = userData;
    },
    login: (state) => {
      state.isLoggedIn = true;
    },
    logout: (state) => {
      state.userData = {};
      state.isLoggedIn = false;
    },
    setBookRiderData: (state, action) => {
      const newData = action.payload;
      for (const key in newData) {
        if (key in state.bookRider) {
          state.bookRider[key] = newData[key];
        }
      }
    },
    clearBookRiderData: (state) => {
      state.bookRider.pickupLocation = "";
      state.bookRider.bookRiderStatus = "firstStep";
      state.bookRider.dropOffLocation = "";
      state.bookRider.dateTime = "";
      state.bookRider.numofPassengers = "";
      state.bookRider.vehicleType = "";
      state.bookRider.currentOrderId = "";
    },
    setBookParcelData: (state, action) => {
      const newData = action.payload;
      for (const key in newData) {
        if (key in state.bookParcel) {
          state.bookParcel[key] = newData[key];
        }
      }
    },
    clearBookParcelData: (state) => {
      state.bookParcel.pickupLocation = "";
      state.bookParcel.bookParcelStatus = "firstStep";
      state.bookParcel.dropOffLocation = "";
      state.bookParcel.currentOrderId = "";
    },
    setDeviceToken: (state, action) => {
      state.deviceToken = action.payload;
    },
    setProfileImage: (state, action) => {
      state.userData.profileImage = action.payload;
    },
    setCurrentRoute: (state, action) => {
      state.currentRoute = action.payload;
    },
    setUserName: (state, action) => {
      state.userData.name = action.payload;
    },
  },
});

export const {
  setUserData,
  login,
  logout,
  setLocation,
  setBookRiderStatus,
  setPickUpLocation,
  setDropOffLocation,
  setBookRiderData,
  clearBookRiderData,
  setBookParcelData,
  clearBookParcelData,
  setDeviceToken,
  setProfileImage,
  setCurrentRoute,
  setUserName,
} = userSlice.actions;

export default userSlice.reducer;
